import { useEffect } from "react"
import { TERMS } from "../../constants/const"
import { Header } from "../../components/common/header/header"

export const Terms = () =>{

    const termsAndCondition = TERMS
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return <>
    <Header isLandingPage={false}/>
      <section  className="page-header -type-1">
        <div  className="container">
          <div  className="page-header__content">
            <div  className="row justify-center text-center">
              <div  className="col-auto">
                <div >

                  <h1  className="page-header__title">Terms & Conditions</h1>

                </div>

                <div >

                  <p  className="page-header__text">We’re on a mission to deliver engaging, curated courses at a reasonable price.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section  className="layout-pt-md layout-pb-lg">
        <div >
          <div  className="row justify-center">
            { termsAndCondition.map(term=>{
                return    <div  className="col-xl-8 col-lg-9 col-md-11">
                <h5>{term.heading}</h5>
                <p  className="mb-30">{term.description}</p>
              </div>
            })}
            <p className=" col-xl-8 col-lg-9 col-md-11 mb-40">
            By scheduling an appointment with WashWoosh, you agree to abide by these Terms and Conditions. If you have any questions or concerns regarding our policies, please contact us.
            </p>
          </div>
        </div>
      </section></>
}