import { useEffect } from "react"
import { REFUND_POLICY } from "../../constants/const"
import { Header } from "../../components/common/header/header"

export const RefundPolicy = () => {
    const termsAndCondition = REFUND_POLICY
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
return <>
<Header isLandingPage={false}/>
      <section className="page-header -type-1">
        <div className="container">
          <div className="page-header__content">
            <div className="row justify-center text-center">
              <div className="col-auto">
                <div >

                  <h1 className="page-header__title">Cancellation and Refunds Policy</h1>

                </div>

                <div >

                  <p className="page-header__text">We understand that circumstances may arise that require you to cancel your service booking.<br/> Please review our cancellation and refund policy below:</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-md layout-pb-lg">
        <div >
          <div className="row justify-center">
            { termsAndCondition.map(term=>{
                return    <div className="col-xl-8 col-lg-9 col-md-11">
                <h5>{term.title}</h5>
                <p className="mb-30">{term.content}</p>
              </div>
            })}
          </div>
        </div>
      </section></>
}
