import { useState } from "react";

export const FAQ =({fagData}) =>{
    const [faqs, setFaqs] = useState(fagData);
      const toggleFAQ = index => {
        setFaqs(
          faqs.map((faq, i) => {
            if (i === index) {
              faq.open = !faq.open;
            } else {
              faq.open = false;
            }
    
            return faq;
          })
        );
      };
    return <>
     <div className="faqs">
        {faqs.map((faq, index) => (
          <div
          className={"faq " + (faq.open ? "open" : "")}
          key={index}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">{faq.question}</div>
          <div className="faq-answer">{faq.answer}</div>
        </div>
        ))}
      </div>
   </>
}