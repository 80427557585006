import { Link } from "react-router-dom"

export const DoorStep = () => {
    return<div > 
        <section className="layout-pt-lg layout-pb-md bg-light-6">
        <div className="container">
            <div className="row y-gap-30 items-center">
                <div className="col-xl-5 offset-xl-1 col-lg-6">
                    <img className="w-1/1" src="img/home-9/hero/ww_admin.png" alt="image1" />
                </div>
                <div className="col-xl-4 offset-xl-1 col-lg-6">
                    <h3 className="text-24 lh-1 animate__animated animate__slideInRight animate__delay-1s">At your doorstep.
                        By your fingertips</h3>
                    <p className="mt-20 animate__animated animate__slideInUp animate__delay-1s" >The most convenient, professional & eco-friendly car wash solution. At your doorstep. On tap of a button.</p>
                        <div className="app-content animate__animated animate__bounce animate__delay-1s">
                           <div className="app-content__buttons">
                                <Link to="#"><img src="img/app/buttons/1.svg" alt="button" /></Link>
                                <Link to="#"><img src="img/app/buttons/2.svg" alt="button" /></Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <div className="svg-shape">
     <svg width="1925" height="261" viewBox="0 0 1925 261" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fill-rule="evenodd" clip-rule="evenodd" d="M1924.67 0L1922.7 7.03707C1911.58 46.7293 1877.25 75.5353 1836.23 79.5878L0 261V0H1924.67Z" fill="#d2f6f5" />
     </svg>
   </div>
    </div>
}
