export const WhyChooseUs = () => {
    return<div> 
    <section className="layout-pt-lg layout-pb-lg bg-light-4">
        <div data-anim-wrap className="container">
            <div className="row justify-center text-center">
                <div className="col-auto">
                    <div className="sectionTitle ">
                        <h2 className="sectionTitle__title ">Why Choose Us?</h2>
                    </div>
                </div>
            </div>
            <div className="row y-gap-30 justify-between pt-60 lg:pt-50">
                <div className="col-lg-4 col-md-12">
                    <div className="coursesCard -type-2 text-center pt-50 pb-40 px-30 bg-white rounded-8">
                        <div className="coursesCard__image">
                            <img src="img/home-5/learning/1.svg" alt="image1" />
                        </div>
                        <div className="coursesCard__content mt-30">
                            <h5 className="coursesCard__title text-18 lh-1 fw-500">Expert 'Bros'</h5>
                            <p className="coursesCard__text text-14 mt-10">Our team comprises skilled professionals, our 'bros' who are extremely passionate about cars and are trained in the latest detailing techniques.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="coursesCard -type-2 text-center pt-50 pb-40 px-30 bg-white rounded-8">
                        <div className="coursesCard__image">
                            <img src="img/home-5/learning/2.svg" alt="image2" />
                        </div>
                        <div className="coursesCard__content mt-30">
                            <h5 className="coursesCard__title text-18 lh-1 fw-500">Premium Products</h5>
                            <p className="coursesCard__text text-14 mt-10">We use only top-of-the-line, eco-friendly products for your vehicle and the environment.Our products not only assures present satisfaction but also pursuade better future for both your ride and environment.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="coursesCard -type-2 text-center pt-50 pb-40 px-30 bg-white rounded-8">
                        <div className="coursesCard__image">
                            <img src="img/home-5/learning/3.svg" alt="image2" />
                        </div>
                        <div className="coursesCard__content mt-30">
                            <h5 className="coursesCard__title text-18 lh-1 fw-500">Convenience</h5>
                            <p className="coursesCard__text text-14 mt-10">With flexible scheduling and mobile services, we make it easy for you to keep your car in pristine condition  at your convenient time and place</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     {/* <div className="svg-shape">
     <svg width="1925" height="261" viewBox="0 0 1925 261" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fill-rule="evenodd" clip-rule="evenodd" d="M1924.67 0L1922.7 7.03707C1911.58 46.7293 1877.25 75.5353 1836.23 79.5878L0 261V0H1924.67Z" fill="#b8edec1f" />
     </svg>
   </div> */}
    </div>
}