import { Link } from "react-router-dom"

export const MainHead = () => {
    return <>
        <section id="top" className="layout-pt-lg layout-pb-md bg-light-6">
            <div className="container">
                <div className="row y-gap-20 justify-between items-center">
                    <div className="col-xl-6 col-lg-7">
                        <div className="app-image mt-5per">
                            <img src="img/home-5/apps/head.png" alt="image1" />
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="app-content animate__animated animate__bounce animate__delay-1s">
                            <h2 className="app-content__title">MAINTENANCE<br /> <span>Anywhere</span></h2>
                            <p className="app-content__text">At WashWoosh, we understand that your car is more than just a vehicle; it's a reflection of your personality and style. That’s why we are dedicated to providing the highest quality car detailing services at your doorstep.
                            With years of experience and a passion for perfection, our team of skilled professionals is committed to restoring your vehicle to its pristine condition. Whether you drive a luxury sedan, a rugged SUV, or a classic vintage car, we have the expertise and a fine taste to meet your detailing needs.</p>
                            <div className="app-content__buttons">
                                <Link to="#"><img src="img/app/buttons/1.svg" alt="button" /></Link>
                                <Link to="#"><img src="img/app/buttons/2.svg" alt="button" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}