import { useEffect, useMemo, useState } from "react";
import { Header } from "../../components/common/header/header"
import Pagination from "../../components/common/pagination/pagination";
import { ROUTE_ENUM } from "../../constants/const";
import { get } from "../../api/api";
let PageSize =5;
export const Inquiry = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const [ error ,setError] = useState(false)
    const [ loader,setLoader] = useState(false)
    const getTableData = async()=>{
        setLoader(true)
        const queryParams = {
            key: 9527
        }
        await get(ROUTE_ENUM.GET_INQUIRY, queryParams)
        .then(response => {
            if (response.status === 200) {
                setLoader(false)
                setData(response.data.result)
            }
        })
        .catch(error => {
           setError(true)
        });
     }

    useEffect(()=>{
        getTableData()
    },[])
    useEffect(() => {
        // Setup logic here
        return () => {
        };
    }, []); 


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage,data]);
    return <>
    { loader ? <div>
        <div className="loader-overlay">
      <div className="loader"></div>
    </div>
    </div> :<><Header isLandingPage={false} />
            <section className="page-header -type-1">
                <div className="container">
                    <div className="page-header__content">
                        <div className="row justify-center text-center">
                            <div className="col-auto">
                                <div >

                                    <h1 className="page-header__title">Inquiry Details</h1>

                                </div>

                                <div >

                                    <p className="page-header__text">We’re on a mission to deliver engaging, curated courses at a reasonable price.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {error ?<>  <h2 className="text-35 lh-12 mt-5">Oops! Something went wrong.</h2></>: <section className="layout-pb-lg">
                <div className="container">
                    <div className="row y-gap-50">
                        <div className="col-lg-12">
                            <table className="table w-1/1">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>NAME</th>
                                        <th>EMAIL</th>
                                        <th>PHONE</th>
                                        <th>SUBJECT</th>
                                        <th>MESSAGE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTableData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.contact}</td>
                                                <td>{item.subject}</td>
                                                <td className="text-container" title={item.message}>{item.message}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                <div className="row justify-center text-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={data.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </section>}
            </> }
      
       
    </>
}