import React from "react";
import 'animate.css';
export const Services = (props) => {
  return (
    <section id="ourServices" className="layout-pt-lg layout-pb-lg bg-light-6">
      <div className="container">
        <div className="row justify-center text-center">
          <div className="col-auto animate__animated animate__slideInUp">
            <div className="sectionTitle">
              <h2 className="sectionTitle__title text-primary">Our Services</h2>
              <p className="sectionTitle__text text-primary" id="sectionTitle">Thousands of satisfied customers and top-rated car maintenance services.</p>
            </div>
          </div>
        </div>
        <div className="row y-gap-30 pt-20">
          <div className="col-lg-3 col-md-6 animate__slideInUp animate__delay-2s">
            <div className="stepCard -type-1 -stepCard-hover">
              <div className="stepCard__content">
                <h4 className="stepCard__title">Regular Washes</h4>
                <p className="stepCard__text">Waterless car bath on regular subscription cleans and leaves the car glossy with basic care to the paint.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 animate__slideInUp animate__delay-3s">
            <div className="stepCard -type-1 -stepCard-hover">
              <div className="stepCard__content">
                <h4 className="stepCard__title">Exterior Detailing</h4>
                <p className="stepCard__text"> Hand wash, waxing, polishing, and paint protection to restore your car’s shine and safeguard against damging elements like UV rays, bird droppings, pigmentations, clear coat scratches</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 animate__slideInUp animate__delay-4s">
            <div className="stepCard -type-1 -stepCard-hover">
              <div className="stepCard__content">
                <h4 className="stepCard__title">Interior Detailing</h4>
                <p className="stepCard__text">Deep cleaning of carpets, seats, and upholstery, along with dashboard and console cleaning, leaving your car’s interior fresh and spotless.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 animate__slideInUp animate__delay-4s">
            <div className="stepCard -type-1 -stepCard-hover">
              <div className="stepCard__content">
                <h4 className="stepCard__title">Specialty Services</h4>
                <p className="stepCard__text"> Ceramic coating, paint correction, headlight restoration, and engine bay cleaning to enhance your vehicle’s appearance and longevity.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
