import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { Toaster,toast } from "sonner";

export const Login = () => {
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        reset,
    } = useForm();
    const onSubmit = (data) => {
        if (data.name === 'admin' && data.password === 'Admin@123') {
            navigate('/ww-admin-inquiry')
            reset()
        } else {
            toast("!Invalid Credentials",{
                style: {
                    background: '#ffe7e5',
                    fontWeight:500,
                    color:'#d06564',
                    lineHeight:1,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    padding:"10px",

                  },
            })
            reset()
        }
    }
    return <>
        <div className="content-wrapper  js-content-wrapper">
        <Toaster richColors='true' position="top-right"  dir="rtl" />
            <section className="form-page js-mouse-move-container">
                <div className="form-page__img">
                    <div className="form-page-composition">
                        <div className="-bg"><img data-move="30" className="js-mouse-move" src="img/login/bg.png" alt="bg" /></div>
                        <div className="-el-1"><img data-move="20" className="js-mouse-move" src="img/home-9/hero/bg.png" alt="image1" /></div>
                        <div className="-el-2"><img data-move="40" className="js-mouse-move" src="img/home-9/hero/ww_admin.png" alt="icon" /></div>
                    </div>
                </div>

                <div className="form-page__content lg:py-50">
                    <div className="container">
                        <div className="row justify-center items-center">
                            <div className="col-xl-8 col-lg-8">
                                <div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
                                    <h3 className="text-30 lh-13">Login</h3>
                                    <form className="contact-form respondForm__form row y-gap-20 pt-30" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Username Or Email</label>
                                            <input type="text" name="title" placeholder="Name"  {...register("name")} />
                                        </div>
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Password</label>
                                            <input type="password" name="title" placeholder="Password"  {...register("password")} />
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" name="submit" id="submit" className="button -md -purple-1 text-white fw-500 w-1/1">
                                                Login
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </>
}
