import React from "react";
import 'animate.css/animate.min.css';
import { BRAND_NAME } from "../../constants/const";
export const Blogs = (props) => {
  return (
    <section id="blogs" className="layout-pt-lg layout-pb-lg">
    <div data-anim-wrap className="container">
      <div className="animate__animated animate__slideInLeft animate__delay-1s row y-gap-20">
        <div className="col-lg-6">
          <div className="sectionTitle">
            <h2 className="sectionTitle__title">Blog</h2>
            <p className="sectionTitle__text">Discover the latest tips and trends in laundry care with Washwoosh's informative blog.</p>
          </div>
        </div>
      </div>

      <div className="row y-gap-30 pt-60">
        {[
          { src: 'img/home-3/blog/blog1.jfif', delay: 'animate__delay-2s', date:'June 1,2024'},
          { src: 'img/home-3/blog/blog2.jfif', delay: 'animate__delay-3s', date:'June 5,2024' },
          { src: 'img/home-3/blog/blog3.jfif', delay: 'animate__delay-4s', date:'June 10,2024' },
          { src: 'img/home-3/blog/blog4.jfif', delay: 'animate__delay-5s', date:'June 15,2024' },
        ].map((item, index) => (
          <div key={index} className="col-lg-3 col-md-6">
            <div className={`blogCard -type-1`}>
              <div className="blogCard__image">
                <img src={item.src} alt="image1" />
              </div>
              <div className="blogCard__content mt-20">
                <a href="blog-single.html" className="blogCard__category">Green Cleaning</a>
                <h4 className="blogCard__title text-17 lh-15 mt-5">Eco-friendly cleaning products and practices that {BRAND_NAME} promotes.</h4>
                <div className="blogCard__date text-14 mt-5">{item.date}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
  );
};
