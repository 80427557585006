
import React, { useState } from "react";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { post } from "../../api/api";
import { ROUTE_ENUM } from "../../constants/const";
import { Toaster, toast } from 'sonner';
const schema = yup
  .object({
    name: yup.string().required("Name is required field").matches(/^[a-zA-Z ]*$/,"Name should be string"),
    email: yup.string().required('Email is required field').email().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is not valid'),
    contact: yup.string().required('Phone is required field').matches(/^\d{10}$/,'Please enter a valid 10-digit phone number.'),
    subject: yup.string().required("Subject is required field"),
    message: yup.string().required("Message is required field")
  })
  .required()
export const Contact = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const [ loading ,setLoading] =useState(false)
  const onSubmit = async (data) => {
    setLoading(true)
    post(ROUTE_ENUM.INQUIRY_POST_API, data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
          toast("Message Sent Successfully" ,{
           className:"success-message-box"
          })
          reset()
        } else {
          setLoading(false)
          toast("Something went wrong",{
            className:"alert-message-box"
          })
        }

      })
      .catch(error => {
        setLoading(false)
        toast("Something went wrong, Try Again!",{
          className:'alert-message-box'
        })
      });
  }
  const handleKeyDown = (event) => {
    const key = event.keyCode;
    const allowedKeys = (
      (key >= 48 && key <= 57) || // Numbers 0-9
      (key >= 96 && key <= 105) || // Numpad 0-9
      key === 8 || // Backspace
      key === 9 || // Tab
      key === 37 || // Left Arrow
      key === 39 || // Right Arrow
      key === 46 // Delete
    );

    if (!allowedKeys) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <section id="contactUs" className="layout-pt-md layout-pb-lg">
        <Toaster richColors='true'  dir="rtl" />
        <div className="container animated">
          <div className="row y-gap-50 justify-between">
            <div className="col-lg-4">
              <h3 className="text-24 fw-500">Keep In Touch With Us.</h3>
              <p className="mt-25">We have a dedicated support team ready to assist you if you need anything!</p>

              <div className="y-gap-30 pt-60 lg:pt-40">

                <div className="d-flex items-center">
                  <div className="d-flex justify-center items-center size-60 rounded-full bg-light-7">
                    <img src="img/contact-1/1.svg" alt="icon" />
                  </div>
                  <div className="ml-20">Gurugram, Haryana-323001<br /> </div>
                </div>

                <div className="d-flex items-center">
                  <div className="d-flex justify-center items-center size-60 rounded-full bg-light-7">
                    <img src="img/contact-1/2.svg" alt="icon" />
                  </div>
                  <div className="ml-20">+91 88008-11370</div>
                </div>

                <div className="d-flex items-center">
                  <div className="d-flex justify-center items-center size-60 rounded-full bg-light-7">
                    <img src="img/contact-1/3.svg" alt="icon" />
                  </div>
                  <div className="ml-20">info@washwoosh.com</div>
                </div>

              </div>
            </div>

            <div className="col-lg-7">
              <h3 className="text-24 fw-500">Working Hours</h3>
              <p className="mt-25">Monday to Saturday: 9 am to 10 pm
                <br /><span className="fw-700">Note that we also provide corporate deals and event solutions.</span>
                <br/>Write to us!</p>

              <form className="contact-form row y-gap-30 pt-60 lg:pt-40" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-6">
                  <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Name</label>
                  <input type="text" name="name" placeholder="Name..." {...register("name")} />
                  <p className="text-danger">{errors.name?.message}</p>
                </div>
                <div className="col-md-6">
                  <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Email</label>
                  <input type="text" name="email" placeholder="Email..."  {...register("email")} />
                  <p className="text-danger">{errors.email?.message}</p>
                </div>
                <div className="col-md-6">
                  <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Contact</label>
                  <input type="text" name="contact" placeholder="Contact..."  onKeyDown={handleKeyDown}  {...register("contact")} />
                  <p className="text-danger">{errors.contact?.message}</p>
                </div>
                <div className="col-md-6">
                  <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Subject</label>
                  <input type="text" name="subject" placeholder="Subject..." {...register("subject")} />
                  <p className="text-danger">{errors.subject?.message}</p>
                </div>
                <div className="col-12">
                  <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">Message</label>
                  <textarea name="message" placeholder="Message..." rows="8" {...register("message")}></textarea>
                  <p className="text-danger">{errors.message?.message}</p>
                </div>
                <div className="col-12">
                  <button type="submit" name="submit" id="submit" className="button -md -purple-1 text-white">
                    {loading ? "Sending Message...." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
