export const API_BASE_URL ="https://washwoosh.azurewebsites.net/"
export const BRAND_NAME="washwoosh"

export const ROUTE_ENUM ={
    INQUIRY_POST_API:"/api/inquiry/insertinquiry",
    GET_INQUIRY:'/api/inquiry/getinquirydetails',
}

export const HOW_WE_HELP = [
    {
        heading: "Comprehensive Car Cleaning",
        description: "We offer thorough interior and exterior cleaning services to make your vehicle look and feel brand new",
        img:"img/help-center/1.svg"
    },
    {
        heading: "Regular Maintenance",
        img:"img/help-center/2.svg",
        description: "Our regular maintenance services include oil changes, tire rotations, brake inspections, and more."
    },
    {
        heading: "Detailing Services",
        img:"img/help-center/3.svg",
        description: "Our detailing packages are designed to restore and enhance your car's appearance."
    },
    {
        heading: "Specialty Treatments",
        img:"img/help-center/4.svg",
        description: "We offer specialty treatments like ceramic coating, paint protection film, and upholstery protection"
    },
   
    {
        heading: "Easy Booking",
        img:"img/help-center/5.svg",
        description: "Book your appointments online through our user-friendly website. Choose the services you need,and let us handle the rest."
    },
  
    {
        heading: "Customer Support",
        img:"img/help-center/6.svg",
        description: "Our customer support team is available to answer any questions you may have."
    },
];

export const HELP_FAQ = [
    {
        question: "What services does WashWoosh offer?",
        answer: "At WashWoosh, we specialize in comprehensive car maintenance services that transform and maintain your vehicle both inside and out. Our services ensure that your car looks and feels brand new.",
        open: true
    },
    {
        question: "Why should I choose WashWoosh for my car maintenance needs?",
        answer: "WashWoosh is a premier car maintenance company dedicated to providing top-quality services. Our experienced team uses the best products and techniques to ensure your vehicle receives the care it deserves.",
        open: false
    },
    {
        question: "What makes WashWoosh different from other car maintenance companies?",
        answer: "At WashWoosh, we believe in delivering exceptional results with a focus on customer satisfaction. Our commitment to quality, attention to detail, and personalized service set us apart from the competition.",
        open: false
    },
    {
        question: "How often should I bring my car to WashWoosh for maintenance?",
        answer: "The frequency of maintenance depends on various factors such as your driving habits, the condition of your vehicle, and environmental conditions. We recommend consulting with our experts to determine a maintenance schedule that suits your needs.",
        open: false
    },
    {
        question: "Can I book an appointment online?",
        answer: "Yes, you can easily book an appointment through our website. Simply choose the services you need, select a convenient date and time, and provide your contact information. We'll take care of the rest.",
        open: false
    },
    {
        question: "Do you offer mobile car maintenance services?",
        answer: "Yes, we understand that convenience is important to our customers. WashWoosh offers mobile car maintenance services, bringing our expertise directly to your location.",
        open: false
    },
    {
        question: "What products do you use for car cleaning and maintenance?",
        answer: "We use high-quality, industry-approved products that are safe for your vehicle and the environment. Our selection of products ensures effective cleaning and maintenance without compromising the integrity of your car.",
        open: false
    },
    {
        question: "How long does a typical car maintenance session take?",
        answer: "The duration of a maintenance session varies depending on the services requested and the condition of your vehicle. Our team works efficiently to complete the job in a timely manner without sacrificing quality.",
        open: false
    },
    {
        question: "Are your services environmentally friendly?",
        answer: "Yes, WashWoosh is committed to environmentally friendly practices. We use eco-friendly products and techniques to minimize our impact on the environment while providing top-notch car maintenance services.",
        open: false
    },
    {
        question: "How can I contact WashWoosh for more information?",
        answer: "You can contact us through our website's contact form, email us at info@washwoosh.com, or call our customer service hotline at (123) 456-7890. We're here to answer any questions you may have.",
        open: false
    }
]

export const TERMS = [
    {
      heading: "Services Provided",
      description: "WashWoosh provides car detailing services including but not limited to exterior detailing, interior detailing, paint correction, ceramic coating, and specialty services as agreed upon with the customer. Customer has to handpick special services to which only the opted services shall be provided. Adjacent or complimenting services shall not be carried out."
    },
    {
      heading: "Appointment Scheduling",
      description: "Customers must schedule appointments in advance. Walk-in appointments may be accommodated based on availability and confirmation from our team."
    },
    {
      heading: "Cancellation, Refund and Rescheduling",
      description: "Cancellation, refund, and rescheduling conditions shall comply with their respective policies mentioned."
    },
    {
      heading: "Vehicle Condition",
      description: "The real time representation of the services/ vehicle condition might differ from the graphical representations. Under all conditions we assure you that we shall take utmost care of any damages caused to your vehicle during the time of providing the service."
    },
    {
      heading: "Payment",
      description: "All subscriptions, and chosen special services shall only be confirmed and carried out on pre-paid models. Payments once paid (if needed) shall be processed for reversal steps in compliance to the company’s refund policy."
    },
    {
      heading: "Liability",
      description: "While we take utmost care in servicing your vehicle WashWoosh shall not be liable for any damage to or loss of personal belongings left inside the vehicle during detailing. Customers are requested to take out any personal belongings like sunglasses, wallets, currencies, cosmetics, documents, electronic gadgets, parking tickets or any other belongings."
    },
    {
      heading: "Completion Time",
      description: "The time required to complete detailing services may vary depending on the type and condition of the vehicle. Customers will be informed of the estimated completion time upon scheduling and visual inspection."
    },
    {
      heading: "Satisfaction Guarantee",
      description: "We strive to achieve customer satisfaction with every service. If you are not satisfied with the results, please inform us immediately, or lately before 2 hours of the services availed and we will work to address your concerns. Natural occurrences being beyond human control, WashWoosh cannot assure for any happenings post service availing."
    },
    {
      heading: "Safety and Security",
      description: "Customers are responsible for ensuring that their vehicle is in a safe and drivable condition before and after detailing services. Since our services do not attend or interfere with any mechanical or electrical working of the vehicle, WashWoosh is not liable for any issues arising from the mechanical or operational condition of the vehicle during or after service providing."
    },
    {
      heading: "Changes to Terms",
      description: "WashWoosh reserves the right to update or modify these Terms and Conditions at any time. Customers will be notified of any changes."
    }
  ];
  
    export const REFUND_POLICY = [
        {
          "title": "Cancellation Policy",
          "content": "Subscriptions made can be revised/pulled back before the second availing of the service in which case INR.100/- shall be charged. For subscriptions, services availed more than once within the package shall be charged individually on instance of discontinuation along with the service/cancellation charges. You may cancel your hand-picked service booking without penalty up to 24 hours before the scheduled service time. Cancellations made within 24 hours of the scheduled service time shall be subjected to a cancellation fee of INR.100/- of the service charges."
        },
        {
          "title": "Refund Eligibility",
          "content": "If you cancel your service booking within the eligible cancellation period, you may be entitled to a full refund of any payments made post deduction of the service charges. Refunds will be processed within 4 working days from the date of cancellation. For subscriptions, services availed more than once within the package shall be charged individually on instance of discontinuation along with the service/cancellation charges."
        },
        {
          "title": "No-shows",
          "content": "Failure to cancel your service booking and not showing up for the scheduled appointment will result in forfeiture of any payments made. No refunds will be issued for no-shows. On rarest instances of WashWoosh no-shows, full amount shall be refunded to the original payment source."
        },
        {
          "title": "Refund Method",
          "content": "Refunds will be issued using the same method of payment used for the original transaction. If payment was made by credit card, refunds will be issued to the same credit card."
        },
        {
          "title": "Contact Us",
          "content": "If you have any questions about our cancellation and refund policy, please contact us at +91 99999-99999"
        }
      ];
      
export const PRIVACY_POLICY = [
            {
                "title": "Payments",
                "content": "You agree to pay a one-time activation fee and a per-message rate to use the Services, with payments processed through your established payment method as per your Support Agreement. You commit to maintaining WashWoosh’s authorization to charge your selected payment method until all fees are paid in full, unless mutually agreed otherwise in writing. Real-time account status regarding text messages sent can be checked via Car Wash Administrator. Failure to pay fees may result in service interruption until all outstanding amounts are settled. WashWoosh is not liable for payment method errors or failures, including refusals to authorize charges. Customized plans may offer individual terms and billing cycles at our discretion, not publicly listed on our site."
            },
            {
                "title": "Acceptable Use",
                "content": "WashWoosh reserves the right to assess and determine violations of its acceptable use policy and Terms at its discretion. Acceptable use of our Services encompasses activities such as promoting car wash discounts, unlimited packages, or referral programs, strictly for lawful purposes. Prohibited activities include actions that could adversely impact the availability, reliability, or stability of WashWoosh's Services, attempting to circumvent security measures, or engaging in illegal activities such as fraud. Violations also extend to misuse of copyrighted or trademarked material, unauthorized data collection, and sending unsolicited marketing messages. Any misuse or illegal activity may result in immediate termination of Services and may be subject to legal consequences under applicable laws."
            },
            {
                "title": "Warranty Disclaimer",
                "content": "WashWoosh provides the services and all related content on an “as is” basis. washWoosh makes no representations or warranties of any kind, whether express, implied, or statutory. to the broadest extent permitted by law, washWoosh disclaims all warranties, including and without limitation, any implied warranties of merchantability, satisfactory quality, fitness for any particular purpose, title, and quiet enjoyment and any warranties arising out of any course of dealing or usage of trade with respect to the services. no verbal advice or written information given by washWoosh, its employees, licensors, licensees, affiliates, or agents or by any other third party shall create any warranty with respect to the services."
            },
            {
                "title": "Indemnification",
                "content": "In addition to your obligations under your Support Agreement with WashWoosh, to the fullest extent allowed by applicable law, You hereby agree to defend, indemnify, and hold harmless WashWoosh and its business and technology partners, underlying technology creators, third-party suppliers, operators and providers, licensors, board members, officers, directors, shareholders, employees, distributors, resellers, affiliates, and agents from and against any damages (whether compensatory, exemplary, punitive, special, consequential, or incidental), losses, liabilities, judgments, fines, settlements, and expenses (including reasonable attorney, expert, and consultant fees and other legal expenses) in connection with any and all claims, allegations, governmental inquiries, demands, causes of action, lawsuits, or proceedings relating to or arising out of or in connection with Your use of the Services, including without limitation any claim or action arising out of or in connection with (i) any alleged act or omission by You that would constitute a breach of these Terms; (ii) any violation of any law, rule, or regulation concerning messaging alleged to have been committed through any use of your WashWoosh account or your use the Services; or (iii) any other use of WashWoosh's Services by You in any manner not authorized by these Terms, in violation of the restrictions herein, or in violation of applicable law.   "
            },
            {
                "title": "Termination",
                "content": "You may terminate your use of the Services at any time by submitting a support ticket through WashWoosh’s user interface. WashWoosh requires a 30-day termination notice. Service may not be cancelled by any other method such as phone, email, or letter. Lack of activity alone does not automatically terminate your account, and you are responsible for service fees until you expressly cancel your account or until your data has been purged (whichever occurs first). In the absence of an explicit cancellation request by support ticket, you agree to pay any and all applicable fees through the date of WashWoosh's termination of your account access. You understand and agree that WashWoosh will make no refund of any fees whether or not the Services have been used. At any time, with or without notice, WashWoosh may terminate your use of the Services or disable your account in whole or in part at WashWoosh's sole discretion. WashWoosh shall bear no liability to you or any third party because of any such action."
            },
            
          
        ]
   