import axios from 'axios';
import { API_BASE_URL } from '../constants/const';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const get = (url, params) => apiClient.get(url, { params });
export const post = (url, data) => apiClient.post(url, data);