import React, { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LandingPage } from "./pages/landingPage/landing";
import { Footer } from "./components/common/footer/footer";
import { Help } from "./pages/help/help";
import { RefundPolicy } from "./pages/refundPolicy/refundPolicy";
import { PrivacyPolicy } from "./pages/privacyPolicy/privacyPolicy";
import { Terms } from "./pages/Terms/terms";
import { FOUR04Page } from "./pages/Four404/four404";
import { Login } from "./pages/login/login";
import { Inquiry } from "./pages/inquiry/inquiry";
import { Loader } from "./components/common/loader/loader";


const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {loading ? <Loader/>:<main className="main-content">
        <Router>
          <div className="content-wrapper js-content-wrapper">
            <Routes>
              <Route path="/" exact element={<LandingPage landingPageData={landingPageData} />} />
              <Route path="/refund-policy" element={<RefundPolicy/>} />
              <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
              <Route path="/terms" element={<Terms/>} />
              <Route path="/help" element={<Help/>} />
              <Route path="/ww-admin" element={<Login/>} />
              <Route path="/ww-admin-Inquiry" element={<Inquiry loading={loading} setLoading={setLoading} />} /> 
              <Route path="*" element={<FOUR04Page/>} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </main>}
   
    </div>

  );
};

export default App;
