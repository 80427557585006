import 'animate.css'
import { FAQ } from '../../components/common/faq/faq';
import { HELP_FAQ, HOW_WE_HELP } from '../../constants/const';
import { useEffect } from 'react';
import { Header } from '../../components/common/header/header';
export const Help = () => {

    const howWeHelp = HOW_WE_HELP
    const helpFaq = HELP_FAQ
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return <>
    <Header isLandingPage={false}/>
      <section className="page-header-type-1 helpHeading">
        <div className="container">
          <div className="page-header__content">
            <div className="row justify-center text-center">
              <div className="col-auto">
                <div >

                  <h1 className="page-header__title">How can we help you?</h1>

                </div>

                <div >

                  <p className="page-header__text">At WashWoosh, we are dedicated to providing exceptional car maintenance services tailored to your needs.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pb-lg">
        <div  className="container">
          <div className="row y-gap-30 justify-between pt-10 lg:pt-50">
          {
            howWeHelp.map(help=>{
                return     <div className="col-lg-4 col-md-6">
                <div className="py-40 px-45 rounded-16 bg-light-4 mh-400">
                  <div className="d-flex justify-center items-center size-70 rounded-full bg-white">
                    <img src={help.img} alt="icon"/>
                  </div>
                  <h4 className="text-20 lh-11 fw-500 mt-25">{help.heading}</h4>
                  <p className="mt-10">{help.description}</p>
                </div>
              </div>
            })
          }
          </div>
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-lg bg-light-4">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-12">

              <div className="sectionTitle ">

                <h2 className="sectionTitle__title ">Frequently Asked Questions.</h2>

                <p className="sectionTitle__text ">At WashWoosh, we are dedicated to providing exceptional car maintenance services tailored to your needs.</p>

              </div>
              <FAQ fagData={helpFaq}/>
            </div>
          </div>
        </div>
      </section></>
}
