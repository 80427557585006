import { Contact } from "../../components/landing/contact"
import { DoorStep } from "../../components/landing/doorStep"
import { HowItworks } from "../../components/landing/howItWorks"
import { Blogs } from "../../components/landing/blogs"
import { MainHead } from "../../components/landing/mainHead"
import { Services } from "../../components/landing/services"
// import { Testimonials } from "../../components/landing/testimonials"
import { WhyChooseUs } from "../../components/landing/whyChooseUs"
import { Header } from "../../components/common/header/header"
export const LandingPage = (props) => {
    const { landingPageData }=props
    return <>
        <Header  isLandingPage={true} />
        <MainHead/>
        <HowItworks data={landingPageData.Features} />
        <Services data={landingPageData.Services} />
        <Blogs data={landingPageData.Gallery} />
        <WhyChooseUs/>
        <DoorStep/>
        {/* <Testimonials/> */}
       
        <Contact />
    </>
}