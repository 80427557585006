import { useEffect } from "react"
import { PRIVACY_POLICY } from "../../constants/const"
import { Header } from "../../components/common/header/header"

export const PrivacyPolicy = () => {
    const privacyPolicy = PRIVACY_POLICY
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return <>
    <Header isLandingPage={false}/>
    <section className="page-header -type-1">
    <div className="container">
      <div className="page-header__content">
        <div className="row justify-center text-center">
          <div className="col-auto">
            <div >

              <h1 className="page-header__title">Privacy and Policy</h1>

            </div>

            <div >

              <p className="page-header__text">We’re on a mission to deliver engaging, curated courses at a reasonable price.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="layout-pt-md layout-pb-lg">
    <div >
      <div className="row justify-center">
        { privacyPolicy.map(term=>{
            return    <div className="col-xl-8 col-lg-9 col-md-11">
            <h5>{term.title}</h5>
            <p className="mb-30">{term.content}</p>
          </div>
        })}
      </div>
    </div>
  </section></>
}
