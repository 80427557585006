import React from "react";

export const HowItworks = (props) => {
  return (
    <section id="howItworks" className="layout-pt-lg layout-pb-lg">
      <div className="container">
        <div className="row y-gap-20 justify-center text-center">
          <div className="col-auto">
            <div className="sectionTitle ">
              <h2 className="sectionTitle__title ">How it works?</h2>
              <p className="sectionTitle__text">Washwoosh aims at providing quality at your convenience</p>
            </div>
          </div>
        </div>

        <div className="row align-self-center justify-center">
          <div className="col-lg-10">
          <div className="row y-gap-30 justify-center pt-60 lg:pt-40">
  <div className="col-xxl-2 col-xl-2 col-lg-6 col-md-6 zoom">
    <div className="d-flex flex-column items-center text-center">
      <div className="relative size-120 d-flex justify-center items-center rounded-full bg-light-4">
        <img src="img/general/Download-2.svg" alt="image1" className="size-80" />
        <div className="side-badge">
          <div className="size-35 d-flex justify-center items-center rounded-full bg-dark-1 -dark-bg-purple-1">
            <span className="text-14 fw-500 text-white">01</span>
          </div>
        </div>
      </div>
      <div className="text-17 fw-500 text-dark-1 mt-30">Download Wash woosh available on iOS or Android</div>
    </div>
  </div>
  <div className="col-auto xl:d-none">
    <div className="pt-30">
      <img src="img/misc/lines/1.svg" alt="icon" />
    </div>
  </div>
  <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 zoom">
    <div className="d-flex flex-column items-center text-center">
      <div className="relative size-120 d-flex justify-center items-center rounded-full bg-light-4">
        <img src="img/general/Mobile login3.svg" alt="image2" className="size-80" />
        <div className="side-badge">
          <div className="size-35 d-flex justify-center items-center rounded-full bg-dark-1 -dark-bg-purple-1">
            <span className="text-14 fw-500 text-white">02</span>
          </div>
        </div>
      </div>
      <div className="text-17 fw-500 text-dark-1 mt-30">Sign up and add your details</div>
    </div>
  </div>
  <div className="col-auto xl:d-none">
    <div className="pt-30">
      <img src="img/misc/lines/1.svg" alt="icon" />
    </div>
  </div>
  <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 zoom">
    <div className="d-flex flex-column items-center text-center">
      <div className="relative size-120 d-flex justify-center items-center rounded-full bg-light-4">
        <img src="img/general/add car_3.svg" alt="image3" className="size-80" />
        <div className="side-badge">
          <div className="size-35 d-flex justify-center items-center rounded-full bg-dark-1 -dark-bg-purple-1">
            <span className="text-14 fw-500 text-white">03</span>
          </div>
        </div>
      </div>
      <div className="text-17 fw-500 text-dark-1 mt-30">Add your vehicle to the profile</div>
    </div>
  </div>

  <div className="col-xxl-2 col-xl-2 col-lg-6 col-md-6 zoom order-6 order-md-6 order-lg-5 ">
    <div className="d-flex flex-column items-center text-center">
      <div className="relative size-120 d-flex justify-center items-center rounded-full bg-light-4">
        <img src="img/general/Payment_1_2.svg" alt="image4" className="size-80" />
        <div className="side-badge">
          <div className="size-35 d-flex justify-center items-center rounded-full bg-dark-1 -dark-bg-purple-1">
            <span className="text-14 fw-500 text-white">06</span>
          </div>
        </div>
      </div>
      <div className="text-17 fw-500 text-dark-1 mt-30">Confirm Order and just sit back and relax</div>
    </div>
  </div>
  <div className="col-auto xl:d-none order-lg-5">
    <div className="pt-30">
      <img src="img/misc/lines/2.svg" alt="icon" />
    </div>
  </div>
  <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 order-4 order-md-4 order-lg-5 zoom ">
    <div className="d-flex flex-column items-center text-center">
      <div className="relative size-120 d-flex justify-center items-center rounded-full bg-light-4">
        <img src="img/general/Select-pana.svg" alt="image5" className="size-80" />
        <div className="side-badge">
          <div className="size-35 d-flex justify-center items-center rounded-full bg-dark-1 -dark-bg-purple-1">
            <span className="text-14 fw-500 text-white">05</span>
          </div>
        </div>
      </div>
      <div className="text-17 fw-500 text-dark-1 mt-30">Select your preferred payment method and our bros will be on his way!</div>
    </div>
  </div>
  <div className="col-auto xl:d-none order-lg-5">
    <div className="pt-30">
      <img src="img/misc/lines/2.svg" alt="icon1" />
    </div>
  </div>
  <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 zoom  order-8 order-md-8  order-lg-5 order-lg-4 ">
    <div className="d-flex flex-column items-center text-center">
      <div className="relative size-120 d-flex justify-center items-center rounded-full bg-light-4">
        <img src="img/general/Select-bro.svg" alt="image6" className="size-80" />
        <div className="side-badge">
          <div className="size-35 d-flex justify-center items-center rounded-full bg-dark-1 -dark-bg-purple-1">
            <span className="text-14 fw-500 text-white">04</span>
          </div>
        </div>
      </div>
      <div className="text-17 fw-500 text-dark-1 mt-30">Select required service/s from the listSelect</div>
    </div>
  </div>
</div>

          </div>
          <div className="col-lg-2 w-14">
            <div className="col-auto xl:d-none" id="curve-arrow">
              <div className="pt-30">
                <img src="img/misc/lines/1.svg" alt="icon" className="rotated-arrow" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};
